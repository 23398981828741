
import React from "react";
import axios from "axios";

import {useLocation} from "react-router-dom";

import '../Apotris.css';

import Header from './header.js';

import background from "../assets/background.png";

import frame1 from "../assets/frame1.png";
import frame2 from "../assets/frame2.png";

import icon_gba from "../assets/icon-gba.png";
import icon_linux from "../assets/icon-linux.png";
import icon_macos from "../assets/icon-macos.png";
import icon_switch from "../assets/icon-switch.png";
import icon_windows from "../assets/icon-windows.png";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Downloads({paypal}){
  let query = useQuery();

  if(paypal){
    paypal = false;

    let value = query.get("value");

    window.open(`https://paypal.me/akouzoukos/${value}eur`,"noreferrer");

    window.open("/apotris/downloads","_blank","noreferrer");
  }

  return(
    <div style={{position: "absolute", width: "100%", backgroundImage: `url(${background})`, backgroundSize:"cover", imageRendering:"pixelated"}}>
      <div className="downloads">
        <Header/>
        <div className="frameCont">
          <div style={{width: "20px", backgroundImage: `url(${frame1})`, backgroundSize: "contain"}}/>
          <Container/>
          <div style={{width: "20px", backgroundImage: `url(${frame2})`, backgroundSize: "contain"}}/>
        </div>
      </div>
    </div>
  );
}

class Container extends React.Component{
  constructor(){
    super();

    this.state = {
      width: window.innerWidth,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render(){
    let mobile = (this.state.width <= 900);

    return(
      <div className={(!mobile)?"container":"container-full"}>
        <div className={(!mobile)?"contents":"contents-full"}>
          <div>
            <Changelog/>
          </div>
        </div>
      </div>
    );
  }
}

class Changelog extends React.Component{

  async count(e,version,link){
    console.log("version");
    e.preventDefault();
    await axios.post('https://apotrisapi.azurewebsites.net/api/UpdateStats?code=yX1cR9zYh5CJT5ugagYyKKKhg4Z7X_ygWcshzlCpuxsVAzFuU4NmYg==',{"version":version})
         .then(res => {
           console.log(res);
         })
         .then(()=>{
           window.open(link,"_self","noreferrer");
         })
         .catch(err => {
           console.log(err);
           window.open(link,"_self","noreferrer");
         });
  }

  render(){
    return (
      <div>
        <h1 style={{ color: "white", textAlign: "center" }}>Downloads</h1>
        <div className="text-downloads">
          <div>
            <h3 style={{display: "flex", flexDirection: "row"}}>
              <div>v4.0.2</div>
            </h3>
            <div className="downloads-box">
              <><b>Downloads:</b></>
              <div>
                <img src={icon_gba} alt="gba" className="icon-downloads"/>
                <a href="" download onClick={(e) => this.count(e,"v4.0.2GBA",'https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.2GBA.zip' )}>Gameboy Advance</a>
              </div>

              <div>
                <img src={icon_windows} alt="windows" className="icon-downloads"/>
                <a href='' download onClick={(e) => this.count(e,"v4.0.2Windows","https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.2Windows-x64.zip")}>Windows</a>
              </div>

              <div>
                <img src={icon_linux} alt="linux" className="icon-downloads"/>
                <a href='' download onClick={(e) => this.count(e,"v4.0.2Linux-x86_64","https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.2Linux-x64rev2.zip")}>Linux x86_64 (rev2)</a>
              </div>

              <div>
                <img src={icon_linux} alt="linux" className="icon-downloads"/>
                <a href='' download onClick={(e) => this.count(e,"v4.0.2Linux-arm64","https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.2Linux-arm64rev2.zip")}>Linux arm64 (rev2)</a>
              </div>

              <div>
                <img src={icon_macos} alt="macos" className="icon-downloads"/>
                <a href='' download onClick={(e) => this.count(e,"v4.0.2MacOS","https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.2MacOS.zip")}>MacOS</a>
              </div>

              <div>
                <img src={icon_switch} alt="switch" className="icon-downloads"/>
                <a href='' download onClick={(e,) => this.count(e,"v4.0.2Switch","https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.2Switch.zip")}>Nintendo Switch</a>
              </div>
              <br/>
              If you are updating from a previous version, make sure to backup your save first!
            </div>
          </div>
          <ul>
            <li>Added Shuffle music cycle option</li>
            <li>Updated some old UI elements to use the new UI style</li>
            <li>Fixed Blitz mode scoring</li>
            <li>Fixed Max Streak stat being reported incorrectly (was +1 higher) in pause/endscreen menu stats</li>
            <li>Fixed replay icon being mangled in Master/Death mode replays</li>
            <li>Score reset key combo now works for Zen mode too(L + R + Select on GBA or special1 + special2 + special3 on other platfomrs)</li>
            <li>(GBA) Merged the GBA/GBARunner2 builds</li>
            <li>(GBA) Memory contract implemented to allow Retro Achievements integration (which is currently WIP <a href="https://retroachievements.org/user/clymax" target="_blank">by clymax</a>).</li>
            <li>(GBA) Added tool that counts audio channels in custom songs and warns you if they go over the limit (<a href="https://github.com/aronson" target="_blank" >by Luigi</a>)</li>
            <li>(GBA) Fixed issues when using Quick/Fast boot options on EZFlash and Everdrive flashcarts (<a href="https://github.com/aronson" target="_blank" >by Luigi</a>)</li>
            <li>(GBA) Fixed issue that caused exiting Sleep to restart the game </li>
            <li>(GBA) Fixed miscellaneous crashes</li>
            <li>(Linux 2024/06/16) Fixed being able to activate zone with 0 meter</li>
          </ul>
          <div>
            <h3 style={{display: "flex", flexDirection: "row"}}>
              <div>v4.0.1</div>
            </h3>
            <div className="downloads-box">
              <><b>Downloads:</b></>
              <div>
                <img src={icon_gba} alt="gba" className="icon-downloads"/>
                <a href="" download onClick={(e) => this.count(e,"v4.0.1GBA",'https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.1GBA.zip' )}>Gameboy Advance</a>
              </div>

              <div>
                <img src={icon_gba} alt="gba" className="icon-downloads"/>
                <a href="" download onClick={(e) => this.count(e,"v4.0.1GBARunner2",'https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.1GBARunner2.zip' )}>GBARunner2</a>
              </div>

              <div>
                <img src={icon_windows} alt="windows" className="icon-downloads"/>
                <a href='' download onClick={(e) => this.count(e,"v4.0.1Windows","https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.1Windows-x64.zip")}>Windows</a>
              </div>

              <div>
                <img src={icon_linux} alt="linux" className="icon-downloads"/>
                <a href='' download onClick={(e) => this.count(e,"v4.0.1Linux","https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.1Linux-x64rev2.zip")}>Linux (rev2)</a>
              </div>

              <div>
                <img src={icon_macos} alt="macos" className="icon-downloads"/>
                <a href='' download onClick={(e) => this.count(e,"v4.0.1MacOS","https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.1.dmg")}>MacOS</a>
              </div>

              <div>
                <img src={icon_switch} alt="switch" className="icon-downloads"/>
                <a href='' download onClick={(e,) => this.count(e,"v4.0.1Switch","https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.1Switch.zip")}>Nintendo Switch</a>
              </div>
              <br/>
              If you are updating from a previous version, make sure to backup your save first!
            </div>
          </div>
          <ul>
            <li>T-Spins no longer count if the active piece's last move was movement due to gravity</li>
            <li>Fixed wrong announcement on No-Clear T-Spin Mini</li>
            <li>Fixed scoring bug that causes line clears to give negative points for 0 combo</li>
            <li>Adjusted explore option so that it triggers every 10 lines instead of 4</li>
            <li>(Switch) Added button labels for joystick inputs</li>
            <li>(Web) Fixed Skin Editor help menu freeze </li>
            <li>(GBA) Fixed GameboyPlayer Rumble (affected Miyoo Mini)</li>
            <li>(GBA) Updated GBA README to include more specific custom song limits (8 channels max is important!)</li>
            <li>(PC/Web/Switch) Fixed song option edgecases causing crashes</li>
            <li>(Linux 2024/4/30) Now works on SteamOS (Steam Deck)</li>
          </ul>
          <div>
            <h3 style={{display: "flex", flexDirection: "row"}}>
              <div>v4.0.0</div>
            </h3>
            <div className="downloads-box">
              <><b>Downloads:</b></>
              <div>
                <img src={icon_gba} alt="gba" className="icon-downloads"/>
                <a href="" download onClick={(e) => this.count(e,"v4.0.0GBA",'https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.0GBA2.zip' )}>Gameboy Advance</a>
              </div>

              <div>
                <img src={icon_gba} alt="gba" className="icon-downloads"/>
                <a href="" download onClick={(e) => this.count(e,"v4.0.0GBARunner2",'https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.0GBARunner2.zip' )}>GBARunner2</a>
              </div>

              <div>
                <img src={icon_windows} alt="windows" className="icon-downloads"/>
                <a href='' download onClick={(e) => this.count(e,"v4.0.0Windows","https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.0Windows-x64.zip")}>Windows</a>
              </div>

              <div>
                <img src={icon_linux} alt="linux" className="icon-downloads"/>
                <a href='' download onClick={(e) => this.count(e,"v4.0.0Linux","https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.0Linux-x64.zip")}>Linux</a>
              </div>

              <div>
                <img src={icon_macos} alt="macos" className="icon-downloads"/>
                <a href='' download onClick={(e) => this.count(e,"v4.0.0MacOS","https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.0.dmg")}>MacOS</a>
              </div>

              <div>
                <img src={icon_switch} alt="switch" className="icon-downloads"/>
                <a href='' download onClick={(e,) => this.count(e,"v4.0.0Switch","https://apotrisstorage.blob.core.windows.net/binaries/Apotris-v4.0.0Switch.zip")}>Nintendo Switch</a>
              </div>
              <br/>
              If you are updating from a previous version, make sure to backup your save first!
            </div>
          </div>
          <ul>
            <li>Tons of new Ports (running natively, no emulation used):</li>
            <ul>
              <li>Windows</li>
              <li>Linux</li>
              <li>MacOS</li>
              <li>Web Browser</li>
              <li>Nintendo Switch</li>
              <li><a href="https://portmaster.games/detail.html?name=apotris&devices=">PortMaster</a> (various retro/emulation handhelds)</li>
            </ul>
            <li>Complete UI Overhall</li>
            <li>Added 3 new Game Modes:</li>
            <ul>
              <li>Death (based on TGM3)</li>
              <li>Zen</li>
              <li>CPU Battle</li>
            </ul>
            <li>Added Game Replays (works up to a certain number of moves)</li>
            <li>Added GBA Wireless Adapter support (<a href="https://github.com/aronson" target="_blank" >by Luigi</a>)</li>
            <li>Added new Options:</li>
            <ul>
              <li>Graphics:</li>
              <ul>
                <li>Graphics Profiles (stores up to 5 different sets of graphics options to quickly swap between your favorites)</li>
                <li>2 Color Background Gradients</li>
                <li>Background Gradient Randomizer</li>
                <li>2 new Grid Options</li>
                <li>1 new Skin</li>
                <li>2 new Ghost Pieces</li>
                <li>1 new Clear Style</li>
                <li>Clear effect direction option (because I can't decide which option looks better)</li>
                <li>Queue/Hold Backgrounds</li>
                <li>Monochrome Palette (#5) now uses Frame Color</li>
                <li>Fluid Simulation Board Effects</li>
                <li>'Smooth' Screen Shake (works more like screen bounce)</li>
                <li>No Clear Text (including streak/combo) Option</li>
                <li>Explore Option (randomizes graphics options every 10 lines cleared)</li>
                <li>Randomize Graphics button (for when you are out of ideas)</li>
                <li>
                  4:3 Aspect Ratio (only affects text, bringing it closer to the
                  center of the screen so that you can overscale the game on 4:3
                  screens without anything getting cut off)
                </li>
              </ul>
              <li>Audio:</li>
              <ul>
                <li>Added option to disable multiple 'piece move' SFX during DAS</li>
                <li>
                  You can now change sound effects and change/add/remove the game's songs on GBA (including adding your favorite russian folk song) without having to be a programmer
                  <div><b>(Read the README that comes along with the ROM for info!)</b></div>
                </li>
              </ul>
              <li>Handling:</li>
              <ul>
                <li>You can now test your handling options right in the handling menu</li>
              </ul>
              <li>Gameplay (New Category):</li>
              <ul>
                <li>Pro Mode (used to have to hold L/R when starting a game)</li>
                <li>Goal Line (shows how many lines left till the goal)</li>
                <li>Rotation System:</li>
                <ul>
                  <li>SRS</li>
                  <li>ARS</li>
                  <li>NRS</li>
                  <li>BARS (made by CreeperCraft)</li>
                </ul>
                <li>Big Mode (used to have to input Konami code on Title Screen)</li>
                <li>Peek Above 20th row (board scrolls up when you build high enough)</li>
                <li>Unpause Countdown (enables/disables countdown after pause)</li>
                <li>Spawn location (shows where next piece will spawn so that you can avoid blockout)</li>
              </ul>
              <li>Saving (New Category):</li>
              <ul>
                <li> SaveType display (GBA only)</li>
                <li> SaveSize display (GBA only)</li>
                <li> Autosave Interval</li>
              </ul>
            </ul>
            <li>Added New Information Menus:</li>
            <ul>
              <li>Stats (all-time gameplay statistics)</li>
              <li>Links (links and QR codes to important websites)</li>
            </ul>
            <li>Added Demo Gameplay during the Title Screen (starts after 10 seconds)</li>
            <li>Added No Gravity Option to Training mode (Level 0)</li>
            <li>Added Countdown after Unpausing (along with an option to disable it)</li>
            <li>Improved the Credits Menu</li>
            <li>Tons of fixes lost to time...</li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v3.4.6</div>
              <div className="link"> <a href='' download onClick={(e) => this.count(e,"v3.4.6",'/files/apotris.gba')}>Download</a> </div>
            </h3>
          </div>
          <ul>
            <li> Fixed Master Mode bug that caused the game to crash if your grade was too high </li>
            <li> Fixed Master Mode issue that made getting cools easier than intended</li>
            <li> Fixed Initial System Type B not allowing IRS and IHS at the same time</li>
            <li> Fixed rumble not stopping when pausing the game</li>
            <li> Fixed training mode saves menu issues</li>
            <li> Fixed training mode saves bugs</li>
            <li> Fixed issue that made it possible to top out during zone</li>
            <li> Fixed bug that caused the next piece in the queue to get skipped when ending zone</li>
            <li> Fixed being able to pause during the zone start animation</li>
            <li> Fixed background showing when returning from sleep during zone</li>
            <li> Fixed 180 spin being enabled in Classic Mode</li>
            <li> Fixed Classic Mode level selector display bug</li>
            <li> Fixed issue that made it impossible change the Playback audio setting</li>
            <li> Fixed being able to bind more than 2 keys to the same action</li>
            <li> Fixed t-spin detection for niche t-spins</li>
            <li> Fixed Big Mode spawn height inconsistencies</li>
            <li> Fixed Big Mode zoned lines display text</li>
            <li> Fixed a few 2P Battle bugs</li>
            <li> Fixed perfect clear attack scoring (combo lines now get added and multi-line perfect clears award correct values)</li>
            <li> Partially fixed background issue for gbarunner2 users that caused a line to show up on the top of the screen (this is a gbarunner2 accuracy issue so it can't really be 100% fixed on my side)</li>
            <li> Added "off" option to L+R quick reset setting</li>
            <li> Added ability to buffer actions and charge DAS during game start</li>
            <li> Added airless(?) kick stall prevention (for kicks that allow you to gain height without bottom of the piece touching anything and thus circumventing lock delay)</li>
            <li> Improved zone meter display accuracy (n/4 values are now actually accurate)</li>
            <li> Hard drop now only buffers if held instead of on press during entry delay (only affects Master Mode)</li>
            <li> Changed IRS behavior. It now behaves closer to other games (making higher zone clears possible)</li>
            <li> Gravity now applies to every movement of ARR (affects V.FAST and INSTANT)</li>
            <li> Training mode saves now reset when exiting the current game</li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v3.4.5</div>
              <div className="link"> <a href='' download  onClick={(e) => this.count(e,"v3.4.5",'/files/apotris v3.4.5.gba')}>Download</a> </div>
            </h3>
          </div>
          <ul>
            <li>Added initial hold/rotation type. Type A activates if the button is held while a new piece spawns. Type B activates if the button was newly pressed during the previous clear/entry delay </li>
            <li> Saving now works on Analogue Pocket openFPGA </li>
            <li> Fixed spawn position for held pieces. Pieces that spawn after holding now have the same spawn logic as other pieces </li>
            <li> Fixed initial hold issue where that caused the first hold to happen twice </li>
            <li> Fixed initial rotation not working in Master Mode with Normal rules </li>
            <li> Fixed save conversion issues that caused crashes during highscore screen </li>
            <li> Fixed clear text showing in Training Mode Load/Save menu </li>
            <li> Fixed bug that caused the game to freeze in Survival Mode after a Perfect Clear </li>
            <li> Disable diagonals no longer affects Classic Mode </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v3.4.4</div>
            </h3>
          </div>
          <ul>
            <li> Added Initial Hold & Initial Rotation toggles in handling settings </li>
            <li> Fixed various UI Bugs </li>
            <li> Fixed bug where pieces would have wrong spawn rotations after pausing </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v3.4.3</div>
            </h3>
          </div>
          <ul>
            <li> Fixed IHS getting stuck to on position </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v3.4.2</div>
            </h3>
          </div>
          <ul>
            <li> IRS and IHS now only work on button hold, and behave more like their TGM counter parts in Master Mode </li>
            <li> Disable Diagonals is now always set to SOFT when playing Master Mode with Normal rules </li>
            <li> Fixed Disable Diagonals soft option not working when rebinding keys </li>
            <li> Fixed in-game score display bug (would show the score modulo 2^16) </li>
            <li> Fixed save conversion from versions 3.2 and before </li>
            <li> Fixed lines not getting cleared if saving during clear delay in Training Mode </li>
            <li> Fixed zone meter animation getting stuck if zone gets activated while it's happening </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v3.4.1</div>
            </h3>
          </div>
          <ul>
            <li> Fixed Master Mode tuning issue on high speed level </li>
            <li> Fixed palette not resetting after zone if player tops out while breaking a record and closing the game </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v3.4.0</div>
              <div className="link"> <a href='' download onClick={(e) => this.count(e,"v3.4.0","/files/apotris v3.4.0.gba")}>Download</a> </div>
            </h3>
          </div>
          <ul>
            <li> Added Master Mode (based on TGM3) available with Normal handling and SRS or classic handling with ARS (secret-grade and promotion exams to be added in the future) </li>
            <li> Added a marathon sub-mode called Zone where clearing lines fills the zone meter, which you can then spend to activate Zone. During zone, lines don't get cleared right away and instead get cleared all at once when zone ends. </li>
            <li> Handling changes: </li>
            <ol>
              <li> You can now set DAS to an exact number of frames (keep pressing left on the Auto Repeat Delay setting to set this) </li>
              <li> Added "Instant" options to ARR and Soft Drop Speed </li>
              <li> "Delay Soft Drop" option to toggle the delay before soft drop starts when holding down </li>
              <li> Disable Diagonals now has 3 options: OFF/SOFT/STRICT. (Strict ignores the second press entirely while soft only ignores it while the first button is held down) </li>
            </ol>
            <li> Added connected skins (3 for now, but I will add more in the future) </li>
            <li> Added Gradient Backgrounds graphics option. If you want to disable this, set it to 0 0 0. </li>
            <li> Renamed previous Background option to "Grid" which describes the setting better </li>
            <li> Pressing Select in Graphics Settings resets them to default </li>
            <li> Added Pro Mode. Enable it by holding L or R while starting a game. With Pro Mode a PPS counter and a correct finesse streak counter get enabled. Also clear delay gets reduced to 0. </li>
            <li> Improvements to Training Mode: </li>
            <ol>
              <li> Added a level selector to Training Mode so you can practice at speed </li>
              <li> If you top out, the board just resets instead of leading to a game over </li>
              <li> Pressing pause opens the "Saves" section if the last time you paused you had "Saves" open </li>
            </ol>
            <li> Added several stats to the post-game stats, including "IGT" or In-Game Time which is a timer that doesn't count during clear delay, so that you can compare your times with other games that don't have it </li>
            <li> Pause menu now hides the board and previews to prevent cheating by pausing to think </li>
            <li> Added the post-game stats and mode text to the pause menu, so you can see all of them while in-game </li>
            <li> Fixed a few UI bugs </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v3.3.0</div>
              <div className="link"> <a href='' download onClick={(e) => this.count(e,"v3.3.0",'/files/apotris v3.3.0.gba')}>Download</a> </div>
            </h3>
          </div>
          <ul>
            <li> Added Classic Mode (based on NES, has both A-type and B-type) </li>
            <li> Added sub-modes for sprint and dig (sprint attack and dig efficiency) </li>
            <li> Added the Skin Editor where you can create your own skins </li>
            <li> Added 2 new skins and a new palette </li>
            <li> Reordered the graphics settings </li>
            <li> You can now see your total playtime in the settings screen (starts tracking from this update onward) </li>
            <li> It is now possible to reset a scoreboard by pressing L + R + Select while viewing it </li>
            <li> Improved DAS/ARR making it more consistent, though DAS might feel a bit faster </li>
            <li> Improved the "disable diagonals" option. Now the second key-press causing the diagonal is pressed when the first key is released, instead of ignored completely. This makes "rolling" the d-pad possible </li>
            <li> You can now input hold or rotate the next piece while in the line clear animation </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v3.2.1</div>
              <div className="link"> <a href='' download onClick={(e) => this.count(e,"v3.2.1","/files/apotris v3.2.1.gba")}>Download</a> </div>
            </h3>
          </div>
          <ul>
            <li> Added a sound effect when big mode gets enabled </li>
            <li> Fixed bug with rumble and added more adjust-ability </li>
            <li> Fixed small place effect issues </li>
            <li> Fixed NES palette bugs </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v3.2.0</div>
            </h3>
          </div>
          <ul>
            <li> Added Gameboy and NES skins and palettes </li>
            <li> Added Big Mode (5x10 mode, input the konami code in the title screen to enable) </li>
            <li> Added Rumble support </li>
            <li> Added a new effect when placing pieces (Place Effect in Graphics Settings) </li>
            <li> Select button can now be bound in Controls </li>
            <li> Added mode name and more stats in the post-game screen </li>
            <li> Announcer is now more excited for Quads </li>
            <li> Fixed incorrect 180 spin kicks </li>
            <li> Fixed other small bugs </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v3.1.0</div>
              <div className="link"> <a href='' download onClick={(e) => this.count(e,"v3.1.0","/files/apotris v3.1.0.gba")}>Download</a> </div>
            </h3>
          </div>
          <ul>
            <li> Combo "walls" now go up all the way to the top </li>
            <li> Placing a piece in combo mode before starting a combo no longer leads to Game Over </li>
            <li> Post-Game stats now keep showing state until reboot </li>
            <li> Fixed Rotate Left/Right labels in Controls settings being </li>
            <li> Fixed bug that caused early top-outs </li>
            <li> Saving on cartridges with both flash and SRAM/FRAM should now be handled correctly </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v3.0.1</div>
              <div className="link"> <a href='' download onClick={(e) => this.count(e,"v3.0.1","/files/apotris v3.0.1.gba")}>Download</a> </div>
            </h3>
          </div>
          <ul>
            <li> Fixed survival mode bug </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v3.0.0</div>
            </h3>
          </div>
          <ul>
            <li> Added 4 Wide Combo Mode </li>
            <li> Added tetr.io style Blitz Mode (scoring should be the same) </li>
            <li> Added Survival Mode </li>
            <li> Added tetresse style Finesse training option to Training Mode </li>
            <li> Added 180 spins (not assigned to any buttons by default) </li>
            <li> Added even more customization options including skins, backgrounds, remappable inputs and a lot more </li>
            <li> Added stats in after-game screen </li>
            <li> Improved menus (hopefully) </li>
            <li> Changed four line clear text and sound effects to "Quad" </li>
            <li> Held piece gets grayed out when holding is not allowed </li>
            <li> Next preview pieces now visible during Ready-Go </li>
            <li> Quick resetting now works during Ready-Go </li>
            <li> Dig garbage "holes" are now always 1 square deep </li>
            <li> Flash saving should now work for some bootleg cartridges (using lesserkuma's goombacolor code) </li>
            <li> Fixed a few bugs, including a couple that affected piece locking </li>
            <li> More that I'm probably forgetting! </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v2.1.1</div>
              <div className="link"> <a href='' download onClick={(e) => this.count(e,"v2.1.1","/files/apotris v2.1.1.gba")}>Download</a> </div>
            </h3>
          </div>
          <ul>
            <li> Fixed a bug that caused the game to lock up on the name input screen </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v2.1.0</div>
            </h3>
          </div>
          <ul>
            <li> Added white frame color </li>
            <li> Added new skin </li>
            <li> Added quick restart shortcut (hold L+R) </li>
            <li> Fixed ultra scoreboard bug </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v2.0.0</div>
              <div className="link"> <a href='' download onClick={(e) => this.count(e,"v2.0.0","/files/apotris v2.0.0.gba")}>Download</a> </div>
            </h3>
          </div>
          <ul>
            <li> Added Ultra Mode </li>
            <li> Added Training Mode </li>
            <li> Added 2 Player Battle Mode </li>
            <li> Added 4 more Music Tracks </li>
            <li> Added a lot of customization options, including a Light Mode for non-backlit GBAs </li>
          </ul>

          <div>
            <h3 style={{ display: "flex", flexDirection: "row" }}>
              <div>v1.0.1</div>
              <div className="link"> <a href='' download onClick={(e) => this.count(e,"v1.0.1","/files/apotris v1.0.1.gba")}>Download</a> </div>
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Downloads;
