import React from "react";
import '../Apotris.css';

import menuIcon from '../assets/menu.png';

function Button(props){
  return(
    <a href={props.link}>
      <button className={props.name}>{props.text}</button>
    </a>
  );
}

class Header extends React.Component{
  constructor(){
    super();

    this.state ={
      width: window.innerWidth,
      opened: false,
    }

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  toggle(){
    let current = !this.state.opened;

    this.setState({
      opened: current,
    })
  }

  render(){
    if(this.state.width <= 900){
      if(!this.state.opened){
        return(
          <div className="header-mobile">
            <button name="Menu" className="menu" style={{backgroundImage: `url(${menuIcon})`}} onClick={this.toggle}> </button>
          </div>
        );
      }else{
        let name = "header-button-mobile";
        return(
          <div className="header-mobile-open">
            <button name="Menu" className="menu" style={{backgroundImage: `url(${menuIcon})`}} onClick={this.toggle}> </button>
            <div style={{display: "flex", flexDirection: "column", alignItems:"flex-start"}}>
              <Button name={name} text="Home" link="/apotris"/>
              <Button name={name} text="Play" link="/apotris/play"/>
              <Button name={name} text="Downloads" link="/apotris/downloads"/>
              <Button name={name} text="FAQ" link="https://github.com/akouzoukos/apotris/wiki/FAQ"/>
              <Button name={name} text="Wiki" link="https://github.com/akouzoukos/apotris/wiki"/>
              <Button name={name} text="Discord" link="https://discord.com/invite/jQnxmXS7tr"/>
              <Button name={name} text="Donate" link="https://ko-fi.com/akouzoukos"/>
              <Button name={name} text="Made by akouzoukos" link="https://twitter.com/a_kouzoukos"/>
              <div style={{height:"10px"}}></div>
            </div>
          </div>
        );
      }
    }else{
      let name = "header-button";
      return(
        <div className="header">
          <Button name={name} text="Home" link="/apotris"/>
          <Button name={name} text="Play" link="/apotris/play"/>
          <Button name={name} text="Downloads" link="/apotris/downloads"/>
          <Button name={name} text="FAQ" link="https://github.com/akouzoukos/apotris/wiki/FAQ"/>
          <Button name={name} text="Wiki" link="https://github.com/akouzoukos/apotris/wiki"/>
          <Button name={name} text="Discord" link="https://discord.com/invite/jQnxmXS7tr"/>
          <Button name={name} text="Donate" link="https://ko-fi.com/akouzoukos"/>
          <Button name={name} text="Made by akouzoukos" link="https://twitter.com/a_kouzoukos"/>
          <div style={{width:"40px"}}></div>
        </div>
      );
    }
  }
}

export default Header;
