// import logo from './logo.svg';
import "./App.css";

import React from "react";

import ApotrisHome from "./apotris/home.js";
import Downloads from "./apotris/downloads.js";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
  // Navigate,
  // Link,
  // useRouteMatch,
  // useParams
} from "react-router-dom";

function App() {
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route path="/apotris" element={<ApotrisHome />} />
        </Routes>
      </HashRouter>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/apotris" element={<ApotrisHome />} />
            <Route
              path="/apotris/downloads"
              element={<Downloads paypal={false} />}
            />
            <Route path="/apotris/wiki" element={<Wiki />} />
            <Route path="/apotris/donate" element={<Donate />} />
            <Route path="/apotris/discord" element={<Discord />} />
            <Route path="/apotris/play" element={<Play />} />
            <Route path="/apotris/git" element={<Git />} />
            <Route
              path="/apotris/downloads/paypal"
              element={<Downloads paypal={true} />}
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

function Home() {
  return (
    <div style={{ "text-align": "center" }}>
      This page is still under construction!
    </div>
  );
}

function Wiki() {
  return (
    <div>
      {window.location.replace("https://github.com/akouzoukos/apotris/wiki")}
    </div>
  );
}

function Donate() {
  return <div>{window.location.replace("https://ko-fi.com/akouzoukos")}</div>;
}

function Discord() {
  return (
    <div>
      {window.location.replace("https://discord.com/invite/jQnxmXS7tr")}
    </div>
  );
}

function Play() {
  window.location.href = "play/index.html";

  return <></>;
}

function Git() {
  <div>{window.location.replace("https://gitea.com/akouzoukos/apotris")}</div>;
}

export default App;
