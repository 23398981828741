import React, {useState} from "react";
import '../Apotris.css';

import Header from './header.js';
import axios from "axios";

import Popup from 'reactjs-popup';

import {
  // BrowserRouter as Router,
  // Routes,
  // Route,
  // HashRouter,
  Navigate,
  Link,
  // useRouteMatch,
  // useParams
} from "react-router-dom";

import background from "../assets/background.png";
import logo from '../assets/logo.png';
import demo1 from '../assets/demo1.webm';
import demo2 from '../assets/demo2.webm';
import demo3 from '../assets/demo3.webm';
import demo4 from '../assets/demo4.webm';
import demo5 from '../assets/demo5.webm';
import demo6 from '../assets/demo6.webm';
import portsImage from '../assets/ports.jpg';
import frame1 from "../assets/frame1.png";
import frame2 from "../assets/frame2.png";

import icon_gba from "../assets/icon-gba.png";
import icon_linux from "../assets/icon-linux.png";
import icon_macos from "../assets/icon-macos.png";
import icon_switch from "../assets/icon-switch.png";
import icon_windows from "../assets/icon-windows.png";

function ApotrisHome(){
  return(
    <div style={{position: "absolute", width: "100%", backgroundImage: `url(${background})`, backgroundSize:"cover", imageRendering:"pixelated"}}>
      <div className="home">
        <Header/>
        <div className="frameCont">
          <div style={{width: "20px", backgroundImage: `url(${frame1})`, backgroundSize: "contain"}}/>
          <Container/>
          <div style={{width: "20px", backgroundImage: `url(${frame2})`, backgroundSize: "contain"}}/>
        </div>
      </div>
    </div>
  );
}

class Container extends React.Component{
  constructor(){
    super();

    this.state = {
      width: window.innerWidth,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render(){
    let mobile = (this.state.width <= 900);
    let type = "container";
    if(mobile)
      type += "-full";

    return(
      <div className={type}>
          <HeaderImage mobile={mobile} style={{overflow: "scroll"}}/>
          <Buttons/>
          <div className={(!mobile)?"contents":"contents-full"}>
              <Description mobile={mobile}/>
              <Videos mobile={mobile}/>
          </div>
      </div>
    );
  }
}

function Emulator(){
  return(
    <div style={{display:"flex", flexDirection:"column", margin: "5px", justifyItems: "center"}}>
      <iframe className="emulator" src="https://gba.ninja/?autorun=https://akouzoukos.com/files/apotris.gba&exclusive" title="Demo"></iframe>
      <div className="text" style={{alignSelf:"center", textAlign:"center"}}>Press Escape for options!</div>
    </div>
  );
}

function HeaderImage(props){
  const [demo,setDemo] = useState(false);

  let type = "logo";
  if(props.mobile)
    type += "-big";

  if(!demo){
    return <img src={logo} alt="Logo" className={type}/>;
  }else{
    return(
      <Emulator/>
    );
  }
}

function Video(props){
  let type = "preview";
  if(props.mobile)
    type += "-full";

  return(
    <video className={type} autoPlay loop muted>
      <source src={props.source} type="video/webm"/>
    </video>
  );
}

function Videos(props){
  let type = "images";
  if(props.mobile)
    type += "-full";

  return(
    <div className={type}>
      <Video source={demo1} mobile={props.mobile}/>
      <Video source={demo3} mobile={props.mobile}/>
      <Video source={demo2} mobile={props.mobile}/>
      <Video source={demo5} mobile={props.mobile}/>
      <Video source={demo6} mobile={props.mobile}/>
      <Video source={demo4} mobile={props.mobile}/>
      <img src={portsImage} alt="ports" style={{imageRendering:"auto"}}/>
    </div>
  );
}

function Description(props){
  let type = "text";
  if(props.mobile)
    type += "-full";

  return(
    <div className={type}>
      <b>Apotris</b> is a multiplatform open-source block stacking game!
      What sets Apotris apart from other block stacking games is its extensive
      customization options, complemented by ultra-responsive controls that let
      you execute your moves with precision. With 14 unique game modes and a plethora of settings,
      you can tailor the game to your preferences, ensuring a fresh and challenging
      experience every time you play.
      Whether you're a casual player or a hardcore enthusiast, Apotris has something for everyone.
      You can even battle your friends using the Gameboy Advance Link
      Cable or Wireless Adapters in 2-Player Battle!
      While Apotris was originally designed for Gameboy Advance, it now
      supports all kinds of platforms, so between the ports and emulation
      you can play Apotris on almost anything.
      <p>
      The game is still actively maintained and new features are added
      constantly. If you have any suggestions, want to learn about
      new features or participate in beta releases, <b>join the
      Apotris <a href="https://discord.com/invite/jQnxmXS7tr">discord server!</a></b>
      </p>

      <h3>
        <b> Game-modes:</b>
      </h3>

      <ul>
      <li><b>Marathon</b>: Try to get the best score as the game gets faster and faster</li>
      <li><b>Sprint</b>: Clear lines to reach the goal as fast as possible</li>
      <li><b>Dig</b>: Dig through lines of garbage</li>
      <li><b>Ultra</b>: Get the best score in a limited time</li>
      <li><b>Blitz</b>: Get the best score in a limited time while the game gets faster and faster</li>
      <li><b>Combo</b>: Aim for the most consecutive clears in a 4-wide well</li>
      <li><b>Survival</b>: Survive as long as possible while garbage rises from below</li>
      <li><b>Classic</b>: Old school stacking mechanics</li>
      <li><b>Master</b>: Get the highest Grade while the game gets aggressively faster</li>
      <li><b>Death</b>: Try to survive at brutal speeds</li>
      <li><b>Zen</b>: The goal is to relax...</li>
      <li><b>2P Battle</b>: Battle your friends through the Link Cable or Wireless Adapter (GBA Only!)</li>
      <li><b>CPU Battle</b>: Go against an AI opponent</li>
      <li><b>Training</b>: Try out new strategies or improve your playing using the included Finesse Trainer</li>
      </ul>

      <h3>
        <b>Credits</b>
      </h3>

      <h4>
        <b>Menu Music:</b>
      </h4>
        <ul>
          <li>
            <a href="https://modarchive.org/index.php?request=view_by_moduleid&query=132794">Track 1</a> by supernao
          </li>

          <li>
            <a href="https://modarchive.org/index.php?request=view_by_moduleid&query=128930">Track 2</a> by substance
          </li>
          <li>
            <a href="https://modarchive.org/index.php?request=view_by_moduleid&query=181688">Track 3</a> by schmid
          </li>
        </ul>
      <h4>
        <b>In-Game Music:</b>
      </h4>

      <ul>
        <li>
          <a href="https://modarchive.org/index.php?request=view_by_moduleid&query=186648">Track 1</a> by Nikku4211
        </li>

        <li>
          <a href="https://modarchive.org/index.php?request=view_by_moduleid&query=128813">Track 2</a> by kb-zip
        </li>

        <li>
          <a href="https://modarchive.org/index.php?request=view_by_moduleid&query=88547">Track 3</a> by curt cool
        </li>

        <li>
          <a href="https://modarchive.org/index.php?request=view_by_moduleid&query=88734">Track 4</a> by Basq
        </li>

        <li>
          <a href="https://modarchive.org/index.php?request=view_by_moduleid&query=124888"> Track 5</a> by xtd
        </li>

        <li>
          <a href="https://modarchive.org/index.php?request=view_by_moduleid&query=118406"> Track 6</a> by panther, karl
        </li>
      </ul>

      <h4>
        <b>Made with:</b>
      </h4>

      <ul>
        <li>Meson</li>
        <li>SDL & SDL_mixer</li>
        <li>Xiph audio</li>
        <li><a href="https://tilengine.org">Tilengine</a></li>
        <li><a href="https://solhsa.com/soloud/">SoLoud</a></li>
        <li><a href="https://github.com/LunarLambda/meson-gba">meson-gba</a></li>
        <li><a href="https://github.com/sdk-seven/libseven">sdk-seven</a></li>
        <li><a href="https://github.com/afska/gba-link-connection">gba-link-connection</a></li>
        <li><a href="https://github.com/evanbowman/gba-rumble">gba-rumble</a></li>
        <li>GBA ROM Saving code from <a href="https://github.com/lesserkuma">Lesserkuma</a>'s goombacolor modification</li>
        <li><a href="http://www.danposluns.com/gbadev/posprintf/index.html">posprintf</a></li>
        <li><a href="https://github.com/laqieer/libsavgba">libsavgba</a></li>
        <li><a href="https://github.com/nightmareci/nanotime/">nanotime</a></li>
      </ul>

      <h4>
        <b>Code Contributors:</b>
      </h4>
      <ul>
        <li><a href="https://github.com/aronson/">Isaac Aronson</a></li>
        <ul>
          <li>Toolchain</li>
          <li>Wireless multiplayer</li>
        </ul>
        <li>CreeperCraft</li>
        <ul>
          <li>Custom rotation system (BARS)</li>
        </ul>
        <li>tanwk</li>
        <ul>
          <li>Explore option prototype</li>
        </ul>
        <li><a href="https://github.com/afska/">afska</a></li>
        <ul>
          <li>Wireless bugfixes</li>
        </ul>
        <li><a href="https://github.com/abouvier">abouvier</a></li>
        <ul>
          <li>Linux Makefile improvements</li>
        </ul>
      </ul>
      <h4>
        <b>Special thanks to:</b>
      </h4>
      <ul>
        <li>all donators</li>
      </ul>
      <ul>
        <li>everyone who tested early version to help find bugs</li>
      </ul>
      <ul>
        <li>the members of the discord server</li>
      </ul>

      Icons by <a href="https://icons8.com/" target="_blank"> icons8</a>
    </div>
  );
}

class Buttons extends React.Component{

  support(){
    window.open("https://paypal.me/akouzoukos/5eur","noreferrer");
  }

  git(){
    window.open("https://gitea.com/akouzoukos/apotris","_blank","noreferrer");
  }

  count(){
    axios.post('https://apotrisapi.azurewebsites.net/api/UpdateStats?code=yX1cR9zYh5CJT5ugagYyKKKhg4Z7X_ygWcshzlCpuxsVAzFuU4NmYg==',{"version":"v3.4.6"})
         .then(res => {
           console.log(res);
         })
         .catch(err => {
           console.log(err);
         });
  }

  render(){
    return(
      <div style={{display: "flex", justifyContent: "center", marginBottom: "20px"}}>
        <Download/>
        <button className ="important-button" onClick={this.git}><b>Source Code</b></button>
      </div>
    );
  }
}

function Download(){
  const [value,setValue] = useState("5.00");

  const handleChange = (event) => {
    console.log(event.target.value);
    setValue(event.target.value);
  }

  const handleSubmit = (event) => {
    const url = "/apotris/downloads";
    const a = document.createElement('a');

    a.href = url;
    a.target = "_blank";
    // a.download = url.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.open(`https://paypal.me/akouzoukos/${value}eur`,"_blank","noreferrer");
  }

  return (
    <div>
    <Popup trigger={
        <div style={{position:"relative"}}>
          <button className="important-button">
            <b>Download Latest (v4.0.2)</b>
          </button>
          <div className="download">
            <img src={icon_gba} alt="gba" className="icon"/>
            <img src={icon_windows} alt="windows" className="icon"/>
            <img src={icon_linux} alt="linux" className="icon"/>
            <img src={icon_macos} alt="macos" className="icon"/>
            <img src={icon_switch} alt="switch" className="icon"/>
          </div>
        </div>
    }
    modal
    >
      {close =>(
        <div className="modal">
          <div className="head">Download Apotris</div>
          <div>
            Apotris is free, but you can support the game's development by donating.
          </div>
          <div
            style={{
              marginTop: '20px',
            }}
          >
            <Link to="/apotris/downloads" onClick={close}>
              No thanks, just take me to the downloads.
            </Link>
          </div>
          <form onSubmit={handleSubmit}>
            <div
                style={{
                  fontSize: '30px',
                  marginTop: '10px',
                  marginBottom: '20px',
                }}
            >
              <span> € </span>
              <input
                value = {value}
                onChange = {handleChange}
                style={{
                  fontSize: '30px',
                }}
              />
            </div>
            <Link to={`/apotris/downloads/paypal?value=${value}`} replace={true} className="paypal a">Donate with Paypal</Link>
          </form>
          </div>
      )}
    </Popup>
    </div>
  )
}

export default ApotrisHome;
